<template>
  <SubjectLayout
    :subject="subject"
    :validateError="validateError"
    class="collection-select"
    :class="{ 'collection-select--disabled': computedDisabled }"
  >
    <div class="collection-select__loading-wrapper" v-if="isFetchingData">
      <b-spinner style="border-width: 2px" />
    </div>
    <SharedSelect
      v-else
      v-model="selected"
      :options="options"
      :hasBorder="false"
      :placeholder="placeholder"
      :disabled="subject.disabled"
    />
  </SubjectLayout>
</template>

<script>
import collectionApi from "@/apis/liff/v2/collection";
import SubjectLayout from "@/components/DynamicForm/SubjectLayout";
import SharedSelect from "@/components/DynamicForm/Select";
import Collection from "@/models/Collection.model.js";
import _ from "lodash";

export default {
  components: {
    SubjectLayout,
    SharedSelect,
  },
  props: {
    subject: {
      type: Object,
      required: true,
    },
    // value: [String, Number, Array, Object],
    value: {
      type: Array,
      default: () => {[]},
    },
    validateError: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      required: false,
    },
    branchId: {
      type: String,
      required: false,
    },
    isRelatedToBranch: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collection: null,
      isFetchingData: false,
    }
  },
  watch: {
    subject: {
      handler: function (val) {
        console.log(val);
        if (val.collection_id) {
          this.fetchCollectionItems();
        }
      },
      immediate: true,
    },
  },
  computed: {
    selected: {
      get() {
        if (Array.isArray(this.value)) {
          return this.value[0];
        }
        return this.value !== null ? [this.value][0] : [];
      },
      set(v) {
        if (v !== null) {
          this.$emit("input", Array.isArray(v) ? v : [v]);
        } else {
          this.$emit("input", null);
        }
      }
    },
    options() {
      if (this.collection === null) {
        return []
      }

      const collectionModel = new Collection(this.collection)

      return _.get(this.collection, 'items', []).sort((a, b) => a.order - b.order).map((item) => {
        return {
          value: {
            type: 'collection_select',
            collection_item_id: item.id,
            collection_id: this.collection.id,
            text: item.is_enabled ? collectionModel.textForOption(item.id) : `(已停用)${collectionModel.textForOption(item.id)}`,
          },
          text: item.is_enabled ? collectionModel.textForOption(item.id) : `(已停用)${collectionModel.textForOption(item.id)}`,
        };
      });
    },
    computedDisabled() {
      return this.disabled || (this.isRelatedToBranch && !this.branchId)
    },
  },
  methods: {
    async fetchCollectionItems() {
      this.isFetchingData = true
      try {
        if (this.isRelatedToBranch) {
          if (this.branchId) {
            const response = await collectionApi.getOptionsForBranch(this.subject.collection_id, this.branchId);
            this.collection = response.data.data;
          } else {
            this.collection = null
          }
        } else {
          const response = await collectionApi.getCollection(this.subject.collection_id);
          this.collection = response.data.data;
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", `讀取 ${this.subject.title} 選項時發生錯誤，請重新整理後再試一次`, "error");
      } finally {
        this.isFetchingData = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.collection-select {
  &__loading-wrapper {
    height: calc(2.6rem + 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    background-color: white;
  }

  &--disabled {
    cursor: not-allowed;
  }
}

::v-deep.collection-select select.s-form-control {
  border: 1px solid #e5e5ea !important;
  border-radius: 5px;
  background-color: white;
}

::v-deep.collection-select--disabled select.s-form-control {
  border: 1px solid #e5e5ea !important;
  background-color: #eee;
  color: #888;
  pointer-events: none;
}
</style>
