<template>
  <div class="is-loading" v-if="isFetchingData">
    <b-spinner style="border-width: 2px" />
  </div>
  <div v-else>
    <div class="app-datetime-picker" :class="{ 'd-flex': timeStyle === 'select_style' }">
      <AppDatePicker
        v-model="date"
        :hasError="hasError"
        :placeholder="placeholderForDate"
        @input="handleInput"
        :dateType="dateCondition"
        :dateOptions="dateTimeOptions"
        :disabled="disabled"
      />
      <AppTimePicker
        v-model="time"
        :hasError="hasError"
        :placeholder="placeholderForTime"
        @input="handleInput"
        :start-time="startTime"
        :end-time="endTime"
        :style-type="timeCondition"
        :slot-minutes="slotMinutes"
        :time-style="timeStyle"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import AppDatePicker from '@/components/AppDatePicker.vue';
import AppTimePicker from '@/components/AppTimePicker.vue';
import moment from 'moment';

export default {
  props: {
    value: [Date, String],
    placeholderForDate: {
      type: String,
      default: ' ',
    },
    timeStyle: {
      type: String,
      default: 'select_style',
    },
    placeholderForTime: {
      type: String,
      default: ' ',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    timeCondition: {
      type: String,
      default: 'unlimited',
    },
    dateCondition: {
      type: String,
      default: 'unlimited',
    },
    dateTimeOptions: {
      type: Array,
      default: () => [],
    },
    startTime: {
      type: String,
      default: '00:00',
    },
    endTime: {
      type: String,
      default: '21:00',
    },
    slotMinutes: {
      type: [Number, String],
      default: 30,
    },
    isFetchingData: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    AppDatePicker,
    AppTimePicker,
  },
  data: () => ({
    date: null,
    time: null,
  }),
  watch: {
    value(val) {
      this.formatValueFromInput(val)
    },
  },
  mounted() {
    this.formatValueFromInput(this.value)
  },
  methods: {
    validate() {
      this.$refs.date.validate()
      this.$refs.time.validate()
    },
    formatValueFromInput(value) {
      if ([null, undefined].includes(value)) {
        return
      } else if (value instanceof Date) {
        this.date = value
        this.time = value
      } else if (typeof value == 'string') {
        const datetime = moment(value)
        this.date = datetime.format('YYYY-MM-DD')
        this.time = datetime.format('HH:mm:ss')
      }
    },
    handleInput() {
      if ([this.date, this.time].includes(null)) {
        this.$emit('input', null)
        return
      }

      this.$emit('input', `${this.date} ${this.time}`)
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/base-colors.scss";

.app-datetime-picker {
  margin: -8px;

  > * {
    flex: 1 1 50%;
    margin: 8px;
  }
}

.is-loading {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: unset;
}
</style>
