<template>
  <div>
    <redeem-modal ref="redeemModal" :coupon="selectedRedeemCode" @confirmed="refresh" />

    <Loading v-if="fetching" />

    <img v-if="deepGet(benefitCard, 'config.page_image')" :src="deepGet(benefitCard, 'config.page_image')"
      class="w-100">

    <div class="benefit-tabs" :class="{ 'pt-3': false === tabsContainImages, 'px-3': false === tabsContainImages }">
      <a class="benefit-tab" v-for="tab in dataSources" :key="tab.path"
        :class="{ 'active-tab': $route.params.tabPath === tab.path }" @click="handleTabChanges(tab)">
        <img v-if="tab.tab_image" :src="tab.tab_image" />
        <template v-else>{{ tab.tab_name }}</template>
      </a>
    </div>

    <div class="px-3 overflow-x-hidden page">
      <template v-if="tabPath">
        <div class="page__title" v-if="deepGet(dataSource, 'form_title')">{{ deepGet(dataSource, 'form_title') }}</div>
        <div class="page__desc" v-if="deepGet(dataSource, 'form_desc')">{{ deepGet(dataSource, 'form_desc') }}</div>

        <div class="benefit-card border border-1 p-2">
          <div v-for="(redeemCode, index) in selectedRedeemCodes" :key="redeemCode.id" class="p-2"
            :style="{
              minWidth: `${100 /
                Math.round(
                  selectedRedeemCodes.length / (selectedRedeemCodes.length > 4 ? 2 : 1)
                )
                }%`,
            }"
          >
            <div
              class="benefit-card__point"
              :class="{ 'benefit-card__point--with-stamp': ['applied', 'expiring_soon'].includes(redeemCode.status) }"
            >
              <span v-if="redeemCode.status == 'applied'">{{ index + 1 }}</span>
              <span v-else-if="redeemCode.status == 'applied_and_not_yet'">未開始</span>
              <span v-else-if="redeemCode.status == 'expiring_soon'">即將<br>到期</span>
              <span v-else-if="redeemCode.status == 'redeemed'">已使用</span>
              <span v-else-if="redeemCode.status == 'expired'">已過期</span>
            </div>
          </div>
        </div>

        <div class="page__content">{{ deepGet(dataSource, "content") }}</div>

        <div class="page__button s-space-y-4 px-0">
          <ShareButton variant="s-btn-outline-primary" @click="handleUseNowButtonClicked" :disabled="redeemableCodes.length === 0"
            v-if="deepGet(dataSource, 'use_now_button_text')">
            {{ deepGet(dataSource, 'use_now_button_text') }}
          </ShareButton>
          <MemberButton v-if="deepGet(dataSource, 'button_text')" :button-text="deepGet(dataSource, 'button_text')"
            :button-url="deepGet(dataSource, 'button_url')" :type="deepGet(dataSource, 'button_action')"></MemberButton>
          <MemberButton v-if="deepGet(dataSource, 'tab_button_text')" :button-style="`s-btn-outline-primary`"
            :button-text="deepGet(dataSource, 'tab_button_text')" :button-url="deepGet(dataSource, 'tab_button_url')"
            :type="deepGet(dataSource, 'tab_button_action')"></MemberButton>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import benefitCardApi from "@/apis/liff/v2/benefit-card";
import ShareButton from "@/components/Page/Liff/Shared/Button";
import Loading from "@/components/Page/Liff/Shared/Loading";
import mixinStaff from "@/mixins/liff/staff";
import themeColor from "@/mixins/liff/themeColor";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import RedeemModal from "@/components/Page/Liff/CouponList/CouponModal.vue";
import { isAfter, isBefore } from "date-fns";
import _ from "lodash";

export default {
  mixins: [mixinStaff, themeColor],
  components: {
    ShareButton,
    Loading,
    MemberButton,
    RedeemModal,
  },
  data() {
    return {
      fetching: true,
      benefitCard: null,
      currentTab: null,
      redeemCodes: [],
      selectedRedeemCode: {},
    };
  },
  computed: {
    ...mapGetters("liffGeneral", ["themeConfig", "meta"]),
    ...mapGetters("liffModule", ["getModule"]),
    moduleConfig() {
      return this.getModule("liff_benefit_card")?.module_config || {};
    },
    dataSources() {
      return _.sortBy(_.get(this.benefitCard, "config.data_source", []), 'order');
    },
    dataSource() {
      return this.dataSources.find((x) => x.path == this.tabPath);
    },
    tabsContainImages() {
      return this.dataSources.some(tab => tab.tab_image)
    },
    benefitCardCustomerId() {
      return this.$route.params.benefitCardCustomerId;
    },
    tabPath() {
      return this.$route.params.tabPath;
    },
    selectedRedeemCodes() {
      return this.redeemCodes.filter(x => x.redeem_code_bundle_id == this.dataSource.redeem_code_bundles[0].hashid)
    },
    redeemableCodes() {
      return this.selectedRedeemCodes.filter(this.redeemCodeIsRedeemable)
    },
  },
  async mounted() {
    await this.init();

    if (this.dataSources.length > 0 && !this.tabPath) {
      this.handleTabChanges(this.dataSources[0])
    }

    this.fetching = false;
  },
  methods: {
    isAfter,
    isBefore,
    deepGet: _.get,
    async init() {
      this.benefitCard = _.get(
        await benefitCardApi.get(this.benefitCardCustomerId),
        "data"
      );
      await this.fetchRedeemCodes();
    },
    handleTabChanges(tab) {
      this.$router.replace({
        name: "LiffBenefitCardHome",
        params: {
          benefitCardCustomerId: this.benefitCardCustomerId,
          tabPath: tab.path,
        },
      }).catch(() => {

      });
    },
    handleUseNowButtonClicked() {
      const redeemCode = this.selectedRedeemCodes.find(this.redeemCodeIsRedeemable)
      if (!redeemCode) {
        return
      }

      this.selectedRedeemCode = redeemCode;
      this.$refs.redeemModal.open();
    },
    async fetchRedeemCodes() {
      this.showLoading = true;
      this.redeemCodes = _.get(
        await benefitCardApi.coupons(this.benefitCardCustomerId),
        "data"
      );
      this.showLoading = false;
    },
    redeemCodeIsRedeemable(redeemCode) {
      return false === ['applied_and_not_yet', 'expired', 'redeemed'].includes(redeemCode.status)
    },
    refresh() {
      this.selectedRedeemCode = {};
      this.fetchRedeemCodes();
    },
  },
};
</script>

<style lang="scss" scoped>
.benefit-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 0.5rem;
  overflow-x: hidden;

  &__point {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    border: dashed 2px #c0c4cc;
    background-color: #f2f2f7;
    color: #979797;
    border-radius: 100%;
    font-weight: 600;
    width: 60px;
    margin: auto;
    padding: 15px;
    white-space: nowrap;
    line-height: 1;

    &--with-stamp {
      background-color: var(--s-primary);
      border: none;
      box-shadow: 0px 4px 4px rgba(44, 44, 46, 0.15);

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-image: url('./images/stamp.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }

      > span {
        display: none;
      }
    }

    &:not(&--with-stamp) {
      opacity: 0.5;
      font-size: 14px;
    }
  }
}

.benefit-tabs {
  display: flex;
  background-color: var(--s-white);
  position: sticky;
  top: 0;
  z-index: 10;

  .benefit-tab {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 52px;
    padding-bottom: 2px;
    color: var(--liff-tab_inactive_color) !important;

    img {
      width: 100%;
      height: auto;
    }

    &.active-tab {
      border-bottom: 2px solid var(--liff-tab-color);
      color: var(--liff-tab-color) !important;
      padding-bottom: 0;
    }
  }
}
</style>

<style lang="scss">
.modal-backdrop {
  opacity: 0.5 !important;
}
</style>
