<template>
  <div class="records-list">
    <div class="record s-mb-3" v-for="item in pointLogs" :key="item.id">
      <div>
        <div class="record__title">{{ item.name }}</div>
        <div class="record__desc">
          <div class="record__date">交易日期 {{ item.date }}</div>
        </div>
      </div>
      <div class="s-text-primary record__point">
        {{ item.point }}
      </div>
      <div class="s-flex s-flex-col s-items-end">
            <p>
              <span class="s-tag">{{ item.type_text }}</span>
            </p>
            <p class="s-text-primary">
              <span class="s-font-bold">{{ item.point_need_text }}</span>
            </p>
          </div>
    </div>
    <PointPagination v-model="page" :totalPage="totalPage"></PointPagination>
  </div>
</template>

<script>
import moment from "moment";
import PointPagination from "@/pages/Liff/Point/PointPagination";
import pointApi from "@/apis/liff/v2/point";

export default {
  components: {
    PointPagination,
  },
  props: {
    eventCode: {
      type: String,
      default: null,
    },
    forceSourceType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pointLogs: [],
      page: 1,
      totalPage: 1,
    };
  },
  watch: {
    page() {
      this.fetchPointLogs();
    },
  },
  mounted() {
    this.fetchPointLogs();
  },
  methods: {
    async fetchPointLogs() {
      try {
        let params = {};

        if (this.forceSourceType) {
          params.force_source_type = this.forceSourceType;
        }

        let res = await pointApi.listPointsLogs(this.eventCode, this.page, params);
        let point_logs = res.data;

        if (!point_logs || !point_logs.length) {
          this.pointLogs = [];
          return;
        }

        this.totalPage = res.meta.last_page;

        const POINT_LOGS_TYPE = Object.freeze({
          1: "加點",
          2: "扣點",
        });

        this.pointLogs = point_logs
          .map((pl) => ({
            id: pl.id,
            name: pl.description,
            point: pl.batch_total_points,
            type: pl.type,
            type_text: POINT_LOGS_TYPE[pl.type],
            date: moment(pl.created_at).format("YYYY/MM/DD"),
            status: "finish",
            point_need_text: pl.point_need > 0 ? `+${pl.point_need}` : pl.point_need,
          }))

        if (res.links.next === null) {
          this.noMore = true;
        }
      } catch (error) {
        console.error(error);
        this.$swal.fire({
          icon: "error",
          title: "錯誤",
          text: "未支援的資料來源",
        });
      }
    },
  },
};
</script>

<style scoped>
.record {
  box-shadow: rgb(from var(--s-primary) r g b / 0.2) 0px 2px 8px 0px;
}
</style>

<style lang="scss" scoped>
.records-list {
  padding: 12px;
  padding-bottom: 0;
  background: #fff;
}

.record {
  border-bottom: 1px solid #e5e5ea;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 5px;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #2c2c2e;
    margin-bottom: 8px;
  }

  &__desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #979797;
    div {
      margin-bottom: 8px;
    }
  }

  &__point {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }
}
</style>
