<template>
  <div class="point-card">
    <div class="point-card__image">
      <img v-if="item.image" :src="item.image" />
    </div>
    <div class="point-card__content">
      <div class="point-card__date">{{ item.date || '' }}</div>
      <div class="point-card__title">{{ item.name }}</div>
      <div class="point-card__subtitle">{{ item.subtitle }}</div>
      <div class="point-card__bottom s-flex s-items-center s-justify-between">
        <div v-if="!item.point" class="point-card__free">免費領取</div>
        <div v-else class="point-card__point">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10" cy="10" r="10" fill="currentcolor"/>
            <path
              d="M6.91 5.718V15H8.431V11.438H10.732C12.994 11.438 14.125 10.476 14.125 8.565C14.125 6.667 12.994 5.718 10.758 5.718H6.91ZM8.431 7.018H10.641C11.304 7.018 11.798 7.135 12.11 7.382C12.422 7.616 12.591 8.006 12.591 8.565C12.591 9.124 12.435 9.527 12.123 9.774C11.811 10.008 11.317 10.138 10.641 10.138H8.431V7.018Z"
              fill="white"
            />
          </svg>

          {{ item.point.toLocaleString() }}
        </div>

        <div v-if="isRedeemed" class="point-card__redeemed">
          <img src="@/pages/Dashboard/Point/stamp-redeemed.png" />
          <!-- <button class="s-btn s-liff-theme-btn-outline s-btn-xs" type="button">
            前往使用
          </button> -->
        </div>
        <div v-else class="point-card__button">
          <button v-if="item.quantity <= 0"
            class="s-btn s-liff-theme-btn-outline s-btn-xs btn-not-enough"
            type="button"
          >
            已閱換完畢
          </button>
          <button v-else-if="item.point > availablePoints.point"
            class="s-btn s-liff-theme-btn-outline s-btn-xs btn-not-enough"
            type="button"
          >
            點數不足
          </button>
          <button v-else
            class="s-btn s-liff-theme-btn-outline s-btn-xs btn-exchange"
            @click="handleExchange(item)"
            type="button"
            :disabled="item.point === null"
          >
            兌換
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        id: 1,
        name: "珐瑯不銹鋼 雙層隨行杯",
        subtitle: "藍色",
        point: 10000,
        status: 1,
      }),
    },
    isRedeemed: {
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters('liffPoint', [
      'availablePoints',
    ]),
  },

  methods: {
    handleExchange(item) {
      this.$emit("exchange", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.point-card {
  display: flex;
  background: white;
  border: 1px solid #e5e5ea;
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;

  .point-card__content {
    padding: 12px;
    width: 100%;
  }

  &__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #979797;
    margin-bottom: 8px;
    font-family: "PingFang TC";
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #2c2c2e;
    margin-bottom: 8px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.408px;
    color: #2c2c2e;
    margin-bottom: 6px;
  }

  &__image {
    max-width: 122px;
    max-height: 122px;
    display:flex;
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__free {
    font-weight: 600;
    font-size: 15px;
    line-height: 32px;
    color: var(--liff-button-color);
  }

  &__point {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: var(--s-primary);
    font-family: "PingFang TC";
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  }

  &__button {
    button {
      font-size: 13px;
      line-height: 18px;
      padding: 6px 16px;
      font-weight: 600;
    }

    .btn-not-enough {
      background: #e5e5ea;
      border: 1px solid #c0c4cc;
      color: #2c2c2e;
      cursor: not-allowed;
    }
  }

  &__redeemed {
    position: absolute;
    right: 10px;
    transform: rotate(-30deg);
    bottom: -20px;
    img {
      width: 80px;
    }
  }
}
</style>
