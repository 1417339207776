<template>
  <Loading v-if="isLoading" />
</template>

<script>
import benefitCardApi from "@/apis/liff/v2/benefit-card";
import Loading from "@/components/Page/Liff/Shared/Loading";
import _ from "lodash";
import { generateModuleConfigGetter } from "@/utils/liff/vueStoreHelpers";
import moduleMap from "@/mixins/liff/memberCenter/moduleMap";

export default {
  mixins: [moduleMap],
  components: {
    Loading,
  },
  data: () => ({
    isLoading: true,
  }),
  methods: {
    getModuleConfig: generateModuleConfigGetter('liff_benefit_card'),
  },
  async mounted() {
    try {
      const res = await benefitCardApi.init('elc')
      const benefitCardCustomerId = _.get(res, 'data.benefit_card_customer_id')
      if (benefitCardCustomerId) {
        this.$router.push({ name: 'LiffBenefitCardHome', params: { benefitCardCustomerId } })
      } else {
        console.warn('Failed to retrieve benefit card for customer.')
      }
    } catch (e) {
      this.isLoading = false
      if (this.getModuleConfig('error_intention')) {
        const intention = this.getModuleConfig('error_intention')
        if (intention.url) {
          window.location.href = intention.url
          return
        }

        if (intention.type) {
          const orgCode = this.$route.params.orgCode || this.$route.params.org_code
          if (this.moduleMap.find((x) => x.type == intention.type)) {
            this.$router.push({
              name: this.moduleMap.find((x) => x.type == intention.type).value,
              params: { org_code: orgCode },
            })
            return
          }
        }

        if (intention.type.startsWith('liff_page')) {
          const orgCode = this.$route.params.orgCode || this.$route.params.org_code
          const path = intention.type.replace('liff_page', `/${orgCode}/liff/page`);
          this.$router.push(path);
          return
        }
      }
      console.error(e)
      this.$swal({
        type: 'error',
        text: _.get(e, 'response.data.message', '領取優惠券失敗'),
        showCancelButton: false,
        backdrop: false,
        reverseButtons: true,
        confirmButtonText: '返回會員中心',
      }).then((result) => {
        if (result.value) {
          this.$router.push({ name: 'LiffMemberCenterHome' })
        }
      })
    }
  },
};
</script>
