import https from "./https";
import store from "@/store";

const rewardMission = {
  async getRewardMission(id, params) {
    const { data } = await https.get(`${store.state.liffGeneral.orgCode}/liff/reward-missions/${id}`, { params })
    return data.data
  },
  async addPoint(id, params) {
    const { data } = await https.post(`${store.state.liffGeneral.orgCode}/liff/reward-missions/${id}/add-point`, params)
    return data
  }
}

export default rewardMission
