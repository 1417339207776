<template>
  <div class="shop-subset-selection" :class="{ 'shop-subset-selection--disabled': disabled }">
    <!-- branch select -->
    <template v-if="showParentBranches">
      <div v-if="isFetchingOptions" class="shop-subset-selection__loading-wrapper mb-2">
        <b-spinner style="border-width: 2px" />
      </div>
      <SharedSelect
        v-else
        class="mb-2"
        v-model="selectedParentBranchId"
        :options="formatBranchModelsToOptions(parentBranchModels)"
        placeholder="請選擇區域"
        :hasBorder="false"
        @input="handleParentBranchSelected"
        :disabled="disabled"
      />
    </template>

    <div v-if="isFetchingOptions" class="shop-subset-selection__loading-wrapper">
      <b-spinner style="border-width: 2px" />
    </div>
    <SharedSelect
      v-else
      v-model="selectedShopId"
      :options="availableShopOptions"
      :placeholder="placeholder"
      :hasBorder="false"
      @input="handleShopSelected"
      :disabled="disabled"
    />
  </div>
</template>

<script>
// NOTE: branch -> 分區或分店, area -> 分區, shop -> 分店
import branchApi from "@/apis/liff/v2/branch"
import branchApiAdmin from "@/apis/branch"
import SharedSelect from "@/components/Page/Liff/Shared/Select"
import branchMixin from "@/mixins/Dashboard/branches"
import _ from "lodash"
import { mapState } from 'vuex'

export default {
  props: {
    value: { // NOTE: branch id of a shop
      type: String,
      required: false,
    },
    branchIds: {
      type: Array,
      required: true,
    },
    showParentBranches: {
      type: Boolean,
      default: false,
    },
    showBranchCode: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "請選擇分店",
    },
    apiSource: {
      type: String,
      default: 'liff',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [branchMixin],
  components: {
    SharedSelect,
  },
  data() {
    return {
      selectedParentBranchId: null,
      selectedShopId: null,
      isFetchingOptions: false,
      candidateShopModels: [],
      parentBranchModels: [],
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    availableShopOptions() {
      if (false === this.showParentBranches) {
        return this.formatBranchModelsToOptions(this.candidateShopModels)
      }

      return this.formatBranchModelsToOptions(this.candidateShopModels.filter(shop => shop.parent_id === this.selectedParentBranchId))
    },
    selectedShopModel() {
      return this.candidateShopModels.find(shop => shop.id === this.selectedShopId)
    },
  },
  watch: {
    value(shopId) {
      this.selectedShopId = shopId
    },
  },
  mounted() {
    this.selectedShopId = this.value
  },
  methods: {
    handleParentBranchSelected() {
      this.selectedShopId = null
      this.$emit('input', null)
      this.$emit('selected', null)
    },
    handleShopSelected() {
      this.$emit('input', this.selectedShopId)
      this.$emit('selected', this.selectedShopId === null ? null : this.selectedShopModel)
    },
    async fetchBranchModels(branchIds) {
      try {
        if (this.apiSource === 'admin') {
          return _.get(await branchApiAdmin.getBranches(this.organization, { branch_ids: branchIds, is_all: true }), 'data.data', [])
        } else {
          return _.get(await branchApi.getBranches({ type: 'subset', branch_ids: branchIds }), 'data.data', [])
        }
      } catch (error) {
        console.error('[BranchSubsetSelection] Failed to fetch branches.')
        console.error(error)
      }
    },
    formatBranchModelsToOptions(branchModels) {
      if (!branchModels) {
        return []
      }
      return this.sortBranch(branchModels, _.get(branchModels, '[0].org_id'), this.showBranchCode)
    },
    // public api: fetch both shop models and parent branch models
    async fetchBranchesOptions() {
      this.isFetchingOptions = true

      if (_.get(this.branchIds, 'length', 0) > 0) {
        this.candidateShopModels = await this.fetchBranchModels(this.branchIds)
        this.parentBranchModels = await this.fetchBranchModels(this.candidateShopModels.map(shop => shop.parent_id))

        if (!!this.selectedShopId && this.selectedParentBranchId === null) {
          this.selectedParentBranchId = _.get(this.selectedShopModel, 'parent_id')
        }
      }

      this.isFetchingOptions = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.shop-subset-selection {
  select.s-form-control {
    width: 100%;
    border: 1px solid #e5e5ea !important;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
  }

  &--disabled select.s-form-control {
    border-color: #cdd6dc;
    background-color: #eee;
    color: #888;
  }

  .s-select__caret svg path {
    fill: #372745;
  }
}

.shop-subset-selection {
  &__loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    background-color: white;
    padding: 4px;
  }

  &--disabled &__loading-wrapper {
    border-color: #cdd6dc;
    background-color: #eee;
    color: #888;
  }
}
</style>

<style lang="scss">
// style overwrites for Dashboard
.admin-panel .shop-subset-selection {
  .s-select__caret {
    bottom: 0;
    transform: translateY(-50%);
  }

  select.s-form-control {
    height: 45px;
    border: 1px solid #cdd6dc !important;
    border-radius: 0;
    color: #372745;
    font-size: .875rem;
    line-height: 14px;
  }

  &__loading-wrapper {
    height: 45px;
    border-radius: 0;
  }
}
</style>
