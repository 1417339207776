<template>
  <div class="text-dark align-self-start app-file-uploader" :class="{ 'app-file-uploader--has-error': hasError }">
    <b-form-file v-if="showDeletedAction" ref="file-input" @change="upload" class="app-file-uploader__file-input"  placeholder="尚未選擇檔案" browse-text="瀏覽" />
    <div class="app-file-uploader__action-bar">
      <a
        v-if="fileUrl && showDeletedAction"
        @click="handleCleanIsClicked"
        class="app-file-uploader__clean-button"
      >
        <i class="fa fa-trash-o"></i>
      </a>
      <a class="app-file-uploader__current-url" :href="fileUrl" target="_blank">
        <template v-if="fileUrl">
          <small v-if="placeholderWhenUploaded === null">目前路徑：{{ fileUrl }}</small>
          <small v-else>{{ placeholderWhenUploaded }}</small>
        </template>
        <template v-else>
          <small v-if="placeholderWhenEmpty === null">目前路徑：未上傳</small>
          <small v-else>{{ placeholderWhenEmpty }}</small>
        </template>
      </a>
    </div>
  </div>
</template>

<script>
import fileMixin from "@/mixins/uploadFile"

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    placeholderWhenUploaded: {
      type: String,
      default: null,
    },
    placeholderWhenEmpty: {
      type: String,
      default: null,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showDeletedAction: {
      type: Boolean,
      default: true,
    }
  },
  mixins: [fileMixin],
  data: () => ({
    fileUrl: null,
  }),
  watch: {
    value() {
      this.fileUrl = this.value
    },
  },
  mounted() {
    this.fileUrl = this.value
  },
  methods: {
    async upload(e) {
      this.$emit('uploading', true)
      try {
        this.fileUrl = await this.uploadFile(e.target.files[0])
        this.$emit("input", this.fileUrl)
      } catch (error) {
        console.error(error)
        this.$emit('error', error)
      } finally {
        this.$emit('uploading', false)
        this.$emit('success', true)
      }
    },
    handleCleanIsClicked() {
      this.fileUrl = null
      this.$refs['file-input'].reset()
      this.$emit("input", this.fileUrl)
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/base-colors.scss";
@import "../../node_modules/font-awesome/css/font-awesome.min.css";

::v-deep.app-file-uploader.app-file-uploader--has-error {
  .app-file-uploader__file-input .custom-file-label {
    border-color: map-get(base-colors.$theme-colors, danger);
  }
}

.app-file-uploader {
  ::v-deep.app-file-uploader__file-input {
    .custom-file-label {
      display: flex;
      border: 1px solid #cdd6dc;
      padding: 0;
      cursor: pointer;

      .form-file-text {
        flex: 1 1 auto;
        padding: 0.375rem 0.75rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:after {
        position: relative;
        flex: 0 0 auto;
        padding: 0.375rem 0.75rem;
      }
    }
  }

  &__action-bar {
    margin-top: 4px;
    display: flex;
    align-items: center;
  }

  &__clean-button {
    margin-right: 8px;
    border-radius: 4px;
    padding: 4px 12px;
    color: map-get(base-colors.$theme-colors, danger);
    cursor: pointer;

    &:hover {
      background-color: map-get(base-colors.$theme-colors, danger);
      color: white;
    }
  }

  &__current-url {
    display: block;
    font-size: 0.875rem;
    color: #007bff;

    &:hover {
      text-decoration: underline;
      color: #007bff;
    }
  }
}
</style>
