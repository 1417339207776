<template>
  <div class="s-layout" :style="colorObject">
    <Loading v-if="isLoading" />

    <div class="s-container">

      <div class="content">
        <Header :meta="meta" :page-title="moduleConfig.page_title || '集點卡'" :page-image="moduleConfig.page_image"
          :page-kv-image="moduleConfig.page_kv_image" :header-section="moduleConfig.header_section" />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import Loading from "@/components/Page/Liff/Shared/Loading";
import { mapGetters, mapState } from "vuex";
import { themeColorFn } from "@/mixins/liff/themeColor";

export default {
  mixins: [themeColorFn({ themeConfigPage: 'benefit_card' })],
  components: {
    Header,
    Loading,
  },
  computed: {
    ...mapGetters("liffGeneral", ["themeConfig", "meta"]),
    ...mapGetters("liffModule", ["getModule"]),
    ...mapState('liffLoading', ['isLoading']),
    moduleConfig() {
      return this.getModule("liff_benefit_card")?.module_config || {};
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-gray-light: #c0c4cc;
}

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--liff-layout_bg_color);
}

.content {
  padding-bottom: 24px;
  background: var(--liff-content_bg_color);
}

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    color: var(--liff-primary_text_color) !important;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color) !important;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--primary-color) !important;
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
  &__button {
    padding: 12px;
    color: var(--liff-primary_text_color) !important;
  }
}

::v-deep .footer {
  max-width: 768px;
  margin: auto;
}
</style>
