import _ from "lodash"

function valueIsNotSet(value) {
  return [undefined, null, ''].includes(value);
}

export function parseFormSubjects(form) {
  if (form.subjects == undefined || form.subjects === null) {
    return [];
  }

  return form.subjects.sort((a, b) => a.order - b.order).map((subject) => {
    let config = subject.config;

    return {
      ...config,
      id: subject.id,
      type: subject.type,
    }
  });
}

export function subjectTypeIcon(type) {
  const iconMapping = {
    text: 'mdi-format-title',
    textarea: 'mdi-format-size',
    radio: 'mdi-radiobox-marked',
    checkbox: 'mdi-checkbox-marked-outline',
    select: 'mdi-chevron-down',
    datetime: 'mdi-clock',
    uploader: 'mdi-upload',
    collection_select: 'mdi-file-tree',
    collection_carousel: 'mdi-file-tree',
    shop_selection: 'mdi-domain',
  }
  return _.get(iconMapping, type, 'mdi-format-title')
}

export const validationRules = {
  'text': {
    integer: {
      title: '整數',
      message: '欄位必須為整數',
      validator (value) {
        return valueIsNotSet(value) || Number.isInteger(Number(value));
      },
    },
    positive_integer: {
      title: '正整數',
      message: '欄位必須為正整數',
      validator (value) {
        return valueIsNotSet(value) || Number.isInteger(Number(value)) && Number(value) > 0;
      },
    },
    email: {
      title: 'E-mail',
      message: '欄位不符合 E-mail 格式',
      validator (value) {
        return valueIsNotSet(value) || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
      },
    },
    mobile_phone: {
      title: '手機號碼',
      message: '欄位不符合手機號碼格式',
      validator (value) {
        return valueIsNotSet(value) || /^09\d{8}$/.test(value);
      },
    },
    url: {
      title: '網址',
      message: '欄位不符合網址格式',
      validator (value) {
        return valueIsNotSet(value) || /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/.test(value);
      },
    },
  }
}
