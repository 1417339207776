<template>
  <div class="carousel-selection" :class="{ 'carousel-selection--has-error': hasError, 'carousel-selection--disabled': disabled }">
    <div class="carousel-selection-field carousel-selection-field--is-loading" v-if="isFetchingData">
      <b-spinner style="border-width: 2px" />
    </div>
    <div class="carousel-selection-field" @click="handleFieldIsClicked" v-else>
      {{ textForField }}
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.38672 13.4707C9.62402 13.4707 9.85254 13.374 10.0107 13.1982L16.418 6.6416C16.5674 6.49219 16.6553 6.29004 16.6553 6.06152C16.6553 5.5957 16.2949 5.23535 15.8291 5.23535C15.6094 5.23535 15.3984 5.32324 15.2402 5.47266L9.39551 11.458L3.54199 5.47266C3.38379 5.32324 3.18164 5.23535 2.94434 5.23535C2.47852 5.23535 2.11816 5.5957 2.11816 6.06152C2.11816 6.29004 2.20605 6.49219 2.36426 6.65039L8.77148 13.1982C8.94727 13.374 9.14941 13.4707 9.38672 13.4707Z"
          fill="var(--liff-primary_text_color)"
        />
      </svg>
    </div>

    <b-modal ref="carousel" centered hide-header hide-footer>
      <template #default="{ close }">
        <div class="carousel-selection-modal" :style="colorObject">
          <button class="carousel-selection-modal__close" @click="close()">
            <img src="@/assets/images/close.svg" />
          </button>

          <div v-if="candidates.length > 0">
            <swiper ref="swiper" :options="swiperOption" @click.native="sliderClicked">
              <swiper-slide v-for="(candidate, idx) in candidates" :key="`${renderCount}-${idx}`">
                <div class="badge__detail__hero">
                  <div class="badge__detail__hero__img">
                    <img
                      v-show="candidate.loaded"
                      :src="candidate.image"
                      @load="candidate.loaded = true"
                      class="img-fluid"
                    />

                    <div v-if="candidate.image && !candidate.loaded" class="text-center p-4">
                      <b-spinner variant="secondary"></b-spinner>
                    </div>
                  </div>
                </div>
                <div class="py-3 border-bottom px-4">
                  <div class="badge__detail__hero__title mt-2">{{ candidate.title }}</div>
                  <div v-html="candidate.description"></div>
                </div>
                <div class="py-3">
                  <SharedButton class="s-btn-bg-primary carousel-selection__select-button">選擇</SharedButton>
                </div>
              </swiper-slide>
            </swiper>

            <div class="swiper-button-prev" @click="decreaseViewingCandidateIndex"></div>
            <div class="swiper-button-next" @click="increaseViewingCandidateIndex"></div>
          </div>
          <div v-else>
            <div class="text-center p-4">
              查無資料
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SharedButton from "@/components/Page/Liff/Shared/Button.vue";
import themeColor from "@/mixins/liff/themeColor";
import deepGet from "lodash/get";
import isEqual from "lodash/isEqual";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  mixins: [themeColor],
  components: {
    SharedButton,
    Swiper,
    SwiperSlide,
  },
  props: {
    // value: [String, Number, Array, Object],
    value: {
      type: Array,
      default: () => {[]},
    },
    placeholder: {
      type: String,
      required: false,
    },
    candidateFetcher: { // format: { title, description, image, value, placeholderWhenSelected }
      type: Function,
      required: true,
    },
    valueMatcher: {
      type: Function,
      default: () => {
        return (candidate, incomingValue) => isEqual(candidate.value, incomingValue)
      },
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      candidates: [],
      selectedCandidate: [],
      swiperOption: {
        loop: true,
        noSwipingSelector: 'button',
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      renderCount: 0,
      isFetchingData: false,
      viewingCandidateIndex: 0,
    }
  },
  computed: {
    textForField() {
      return deepGet(this.selectedCandidate, 'placeholderWhenSelected') || this.placeholder || '請選擇'
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.updateSelectedCandidate(val[0])
      } else {
        this.updateSelectedCandidate(val)
      }
    },
  },
  async mounted() {
    await this.fetchCandidates()
  },
  methods: {
    sliderClicked (event) { // NOTE: loop 模式時，swiper 會 copy 第一張與最後一張 slide 的 DOM 元素，但是 event listeners 會遺失，因此改將事件綁在更外層的 swiper element
      if (event.target.classList.contains('carousel-selection__select-button')) {
        const candidate = this.candidates[this.viewingCandidateIndex]
        this.handleCandidateIsSelected(candidate)
        this.$refs.carousel.hide()
      }
    },
    async fetchCandidates() {
      this.isFetchingData = true
      this.$set(this, 'candidates', (await this.candidateFetcher()).map(candidate => ({ ...candidate, loaded: false })))
      this.updateSelectedCandidate(this.value)
      this.renderCount++
      this.isFetchingData = false
    },
    updateSelectedCandidate(incomingValue) {
      this.selectedCandidate = this.candidates.find(candidate => this.valueMatcher(candidate, incomingValue))
    },
    handleFieldIsClicked() {
      if (this.disabled) {
        return
      }
      this.$refs.carousel.show()

      if (this.selectedCandidate) {
        this.viewingCandidateIndex = this.candidates.indexOf(this.selectedCandidate)

        this.$nextTick(() => {
          this.$refs.swiper.swiperInstance.slideTo(this.candidates.indexOf(this.selectedCandidate) + 1) // NOTE: loop 模式時 slideTo() function 不精準，需要將 index + 1 才是正確的位置
        })
      }
    },
    handleCandidateIsSelected(candidate) {
      this.selectedCandidate = candidate

      this.$emit('input', [candidate.value])
    },
    decreaseViewingCandidateIndex() {
      this.viewingCandidateIndex = (this.candidates.indexOf(this.selectedCandidate) - 1 + this.candidates.length) % this.candidates.length
    },
    increaseViewingCandidateIndex() {
      this.viewingCandidateIndex = (this.candidates.indexOf(this.selectedCandidate) + 1) % this.candidates.length
    },
  }
}
</script>

<style lang="scss" scoped>
.carousel-selection-field {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 8px 12px;
  font-weight: inherit;
  font-size: 16px;
  background-color: white;
  color: var(--liff-primary_text_color);
  cursor: pointer;

  &:focus {
    border-color: var(--liff-primary_text_color);
  }

  &--is-loading {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: unset;
  }
}

.carousel-selection--has-error {
  .carousel-selection-field {
    border-color: #fe0000;
  }
}

.carousel-selection--disabled {
  .carousel-selection-field {
    background-color: #eee;
    color: #888;
    cursor: not-allowed;
  }
}

.carousel-selection-modal {
  &__close {
    position: absolute;
    top: -3rem;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    border-radius: 100%;
    aspect-ratio: 1;
    padding: 10px;
    line-height: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    padding: 0 8px;
    background: white;
    border-radius: 40px;
    position: absolute;
    bottom: 12px;
    z-index: 1;
    aspect-ratio: 1;
    width: 40px;
    height: 40px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);

    &::after {
      color: black;
      font-size: 18px;
    }
  }

  .swiper-button-prev {
    left: -5px;
  }
  .swiper-button-next {
    right: -5px;
  }
}

::v-deep .modal-content {
  border-radius: 10px;
  font-size: 15px;
}

::v-deep .carousel-selection__select-button {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  font-size: var(--s-text-xl);
  font-weight: var(--s-weight-medium);
  line-height: var(--s-text-xl-line-height);
}
</style>

<style lang="scss">
.modal-backdrop {
  opacity: 0.5;
}

// style overwrites for Dashboard
.admin-panel .carousel-selection-field {
  height: 45px;
  border: 1px solid #cdd6dc;
  border-radius: 0;
  color: #372745;
  font-size: .875rem;
  line-height: 14px;

  svg path {
    fill: #372745;
  }
}
</style>
