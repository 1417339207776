import https from "./https";
import store from "@/store";

const collectionApi = {
  async getCollection(id) {
    const organization = store.state.liffGeneral.orgCode
    return https.get(`${organization}/liff/collections/${id}`)
  },
  async getOptionsForBranch(id, branchId) {
    const organization = store.state.liffGeneral.orgCode
    return https.get(`${organization}/liff/collections/${id}/branch/${branchId}`)
  },
  async getOptionsForStaff(id, staffId) {
    const organization = store.state.liffGeneral.orgCode
    const params = {
      staff_id: staffId
    }
    return https.get(`${organization}/liff/collections/${id}/items`, {params})
  },
  async getCollectionStaff(id) {
    const organization = store.state.liffGeneral.orgCode
    return https.get(`${organization}/liff/collection-items/${id}/staffs`)
  },
};

export default collectionApi
